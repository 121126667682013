.application_details {
  &__timeline_view {
    &__date {
      &_container {
        &.sticky {
          position: sticky;
          top: 0px;
          left: 0;
          width: 100%;
          background-color: white;
          z-index: 100;
          padding: 8px 55px;

          > p {
            margin: 0;
          }
        }
      }
      color: rgba(5, 5, 82, 0.6);
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.32px;
      margin-bottom: 8px;
      margin-left: 8px;
      margin-top: 20px;
    }

    &__container {
      grid-area: timeline;
      padding: 0 32px 0 32px !important;

      > .MuiTimeline-root {
        margin-top: 0 !important;
      }
    }

    &__item__container {
      min-height: max-content !important;
      padding-top: 0 !important;

      &:hover {
        p {
          color: rgba(5, 5, 82, 0.8);
          font-family: "Inter";
          font-weight: 500;

          span {
            color: rgba(5, 5, 82, 0.6);
            font-weight: 500;
          }
        }

        .MuiTimelineDot-root {
          border-color: rgba(5, 5, 82, 0.8);
        }
      }
    }

    &__separator {
      position: relative;
      top: 10px;
      min-height: 52px;

      &.status {
        > .MuiTimelineDot-root {
          border-radius: 0;
          transform: rotate(45deg);
          background-color: rgba(5, 5, 82, 0.1);
        }
      }
      > .MuiTimelineDot-root {
        border-radius: 50%;
        border: 2px solid rgba(5, 5, 82, 0.1);
        background-color: white;
        height: 16px;
        width: 16px;

        &:hover {
          pointer-events: none;
          cursor: default;
        }
      }
      > .MuiTimelineConnector-root {
        border: 1px solid rgba(5, 5, 82, 0.1);
      }
    }

    &__timeline_content {
      &__time {
        flex: 0 0 auto;
        height: 50px;
        &_container {
          border-radius: 6px;
          padding: 8px;
          margin-left: auto;
          display: flex;
          justify-content: end;
          margin-right: 16px;
          width: 80px;
          > p {
            color: rgba(5, 5, 82, 0.4);
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

      &__module {
        min-height: 50px;

        &_dot_icon {
          margin: 0 8px;
        }

        &_flag_icon {
          margin-left: 12px;
        }

        &_container {
          margin-left: 20px;
          &__overview {
            width: 780px;
            width: 100% !important;
            flex-grow: 1;
            border-radius: 6px;
            background: #fff;
            padding: 8px;
            display: flex;
            justify-content: space-between;
            height: 42px;

            transition: 0.1s ease;

            &.expanded {
              border: solid 0.5px rgba(5, 5, 82, 0.1);
              > p {
                color: rgba(5, 5, 82, 0.8);
              }
            }

            &.status {
              justify-content: center;
              align-items: center;

              > p {
                font-size: 14px;
                font-weight: 400;
                font-family: "Inter";
              }
            }

            &.resume {
              background: none !important;

              > p {
                width: max-content;
              }
            }

            &.started {
              background-color: rgba(38, 132, 255, 0.1);
              border: 1px solid rgba(38, 132, 255, 0) !important;

              &:hover {
                border: 1px solid rgba(38, 132, 255, 0.1) !important;
              }

              > p {
                color: #0747a6;
              }
            }

            &.user_cancelled,
            &.kyc_in_progress,
            &.error {
              background: #f4f5f7;
              border: 1px solid rgba(5, 5, 82, 0);
              &:hover {
                border: 1px solid rgba(5, 5, 82, 0.05);
              }
              > p {
                color: rgba(5, 5, 82, 0.8);
              }
            }

            &.auto_declined,
            &.manually_declined {
              background: #ffebe6;
              border: 1px solid rgba(191, 38, 0, 0);
              &:hover {
                border: 1px solid rgba(191, 38, 0, 0.05);
              }
              > p {
                color: #bf2600;
              }
            }

            &.auto_approved,
            &.manually_approved {
              background: rgba(1, 191, 131, 0.1);
              border: 1px solid rgba(1, 191, 131, 0);
              &:hover {
                border: 1px solid rgba(1, 191, 131, 0.1);
              }

              > p {
                color: #064;
              }
            }

            &.needs_review {
              background: rgba(255, 225, 155, 0.35);
              border: 1px solid rgba(255, 225, 155, 0);

              &:hover {
                border: 1px solid rgba(255, 225, 155, 0.4);
              }

              > p {
                color: #9a4524;
              }
            }

            > p {
              color: rgba(5, 5, 82, 0.6);
              font-family: "Inter";
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              margin: 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              > span {
                color: rgba(5, 5, 82, 0.4);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
              }
            }

            &:hover {
              box-shadow: 0px 0px 15px 0px rgba(5, 5, 82, 0.1);
              cursor: default;

              > p #chevron_icon {
                background-color: rgba(5, 5, 82, 0.8);
              }
            }
          }

          &__details {
            margin-top: 2px;
            margin-bottom: 20px;
            border-radius: 0px 0px 6px 6px;

            > .card_type_five__container {
              padding-top: 12px;
            }

            .generic_common_card__tab__body__table {
              max-width: 760px;
            }
          }
        }

        &_status {
          padding: 4px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          width: 132px;
          margin: 0;
          font-family: Inter !important;
          font-size: 13px !important;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 18px;
        }
      }

      &__resume {
        &__content {
          display: flex;
          flex-direction: row;
          > p {
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: rgba(5, 5, 82, 0.6);

            &:first-of-type {
              margin-right: 12px;
            }

            &:last-of-type {
              margin-left: 12px;
            }

            > span {
              font-weight: 600;
            }
          }

          > hr {
            border: 0.1px dashed rgba(5, 5, 82, 0.2);
            height: 1px !important;
            position: relative !important;
            top: 100% !important;
            margin: 0;
            margin-top: 25px;
            flex-grow: 1;
          }
        }
      }
    }

    &__end {
      &__container {
        width: 100%;
      }
      &__content {
        flex-grow: 1;
      }
      &__line {
        &__container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          gap: 12px;
          padding: 16px 0;
          color: #6b7280;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            sans-serif;
        }

        flex-grow: 1;
        height: 1px;
        background: #d1d5db;
        position: relative;

        &::after,
        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          background: #9ca3af;
          transform: rotate(45deg);
          top: -2.5px;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }

      &__diamond {
        width: 12px;
        height: 12px;
        background: rgba(5, 5, 82, 0.4);
        transform: rotate(45deg);
      }

      &__text {
        &__container {
          width: max-content;
          margin: auto;
        }
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: rgba(5, 5, 82, 0.6);
        margin-top: 0;
      }
    }


    &__resume__container {
      min-height: 40px !important;
    }
  }
}

#current-date {
  &.hidden {
    display: none !important;
  }
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  background: #fcfcff;
  padding: 5px 56px;
  left: 0;
  z-index: 100;
  top: 56px;
  color: rgba(5, 5, 82, 0.6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  border-bottom: 1px solid rgba(5, 5, 82, 0.1);
}

@media (max-width: 1280px) {
  .application_details {
    &__timeline_view {
      &__timeline_content {
        &__module {
          &_container {
            min-width: 880px;
          }
        }
      }
    }
  }
}

@media (max-width: 1092px) {
  .application_details {
    &__timeline_view {
      &__timeline_content {
        &__module {
          &_container {
            min-width: 760px;
          }
        }
      }
    }
  }
}

@media (max-width: 1092px) {
  .application_details {
    &__timeline_view {
      &__timeline_content {
        &__module {
          &_container {
            min-width: 600px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .application_details {
    &__timeline_view {
      &__timeline_content {
        &__module {
          &_container {
            min-width: 500px;
          }
        }
      }
    }
  }
}

@media (max-width: 712px) {
  .application_details {
    &__timeline_view {
      &__timeline_content {
        &__module {
          &_container {
            min-width: 400px;
          }
        }
      }
    }
  }
}
