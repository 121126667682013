@import "../../../App.scss";

@mixin application_status_tag_approved {
    background-color: #e3fcef !important;
    color: #006644 !important;
}

@mixin application_status_tag_declined {
    background-color: #ffebe6 !important;
    color: #bf2600!important;
}

@mixin application_status_classes {
    @include application_status_tag_common();
    background-color: #f4f5f7 !important;
    color: rgba(5, 5, 82, 0.6) !important;

    &.auto_approved,
    &.pass,
    &.manually_approved {
        @include application_status_tag_approved();
    }

    &.auto_declined,
    &.fail,
    &.manually_declined,
    &.high {
        @include application_status_tag_declined();
    }

    &.needs_review,
    &.manualreview,
    &.potentialmatch {
        background-color: #ffe19b59 !important;
        color: #9a4c24 !important;
    }

    &.user_cancelled,
    &.retake,
    &.unexpectederror {
        background-color: #f4f5f7 !important;
        color: rgba(5, 5, 82, 0.6) !important;
    }

    &.started {
        background-color: #deebff !important;
        color: #0747a6 !important;
    }

    &.error {
        background-color: #4f148f1a !important;
        color: #4f148f !important;
    }
}

.status_tag {
    @include application_status_classes();
}