@import "../../../App.scss";

$text_color: rgba(5, 5, 82, 0.8);
$active_text_color: #554ef1;
$fail_border_color: #dd4a46;
$item_active_border_color: rgba(85, 78, 241, 0.2);
$item_active_bg_color: rgba(85, 78, 241, 0.1);
$item_hover_border_color: rgba(5, 5, 82, 0.08);
$item_hover_bg_color: rgba(5, 5, 82, 0.04);
$icon_color: #373775;
$icon_active_color: #554EF1;

@mixin commonSectionStyle {
  h2 {
    @include overline_heading();
    margin: 0;
  }

  &__no_module {
    @include base_font_and_inputs();
    color: rgba(5, 5, 82, 0.6);
  }

  &__item {
    padding: 12px 8px;
    border: solid 0.5px transparent;
    border-radius: 6px;
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &__status__fail {
      display: inline-block;
      width: 10px;
      height: 10px;
      border: solid 2px $fail_border_color;
      border-radius: 50%;
      background-color: transparent;
      flex: 0 0 auto;
      align-self: center;
      margin-left: auto;
    }

    >svg {
      >g {
        fill: $icon_color;
      }

      >path {
        stroke: $icon_color;
      }

      width: 16px;
      height: 16px;
      margin-right: 14px;
      position: relative;
      flex-shrink: 0;
    }

    >span {
      display: inline-block;
      @include sub_heading();
      color: $text_color;
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.active {
      border-color: $item_active_border_color;
      background-color: $item_active_bg_color;
      cursor: pointer;

      >svg {
        >g {
          fill: $icon_active_color;
        }
      }

      >span {
        @include heading_inside_modal_semi_bold();
        color: $active_text_color;
      }
    }

    &:hover:not(.active) {
      border-color: $item_hover_border_color;
      background-color: $item_hover_bg_color;
      cursor: pointer;
    }
  }
}

#leftPanel {
  &__container {
    width: 100%;
    border-radius: 6px;
    border: solid 0.5px rgba(5, 5, 82, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }

  &__header {
    display: flex;
    gap: 8px;
    flex-direction: column;
    @include commonSectionStyle();
  }

  &__body {
    @include commonSectionStyle();
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    &__modules {
      height: 100%;
      overflow: auto;
      overflow-y: scroll;
      scrollbar-width: thin;
    }
  }
}