@import url('https://fonts.googleapis.com/css2?family=Lato');
@import url('https://use.typekit.net/hif3jcz.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
$background_color: #00034d;
$background_blue: #f6f7ff;
$hyperblue: #00034d;
$hyperYellow: #ffdc47;
$grey_border: #c2c4cb;
$grey_border2: #dbdbdb;
$clickable_blue: #335aff;
$border_grey: #fff;
$success_green: #01bf83;
$place_holder_text: #a7a7a7;
$select_blue: #ebedff;
$audit_button_border_color: rgba(5, 5, 82, 0.1);
$audit_button_box_shadow_color: rgba(85, 78, 241, 0.1);
$audit_button_hover_border_color: rgba(85, 78, 241, 0.1);
$audit_button_active_border_color: #554ef1;
$audit_button_color: #554ef1;
$subheading_font_size: 14px;
$audit_button_text_color: rgba(5, 5, 82, 0.8);

$font_family: 'Inter', sans-serif;
$font_inter: 'Inter', sans-serif !important;

* {
  box-sizing: border-box;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 3s ease-in-out;
}

body {
  background-color: #f6f7ff;
  font-family: $font_family !important;
  margin: 0;
  padding: 0;
}

.App {
  width: 100vw;
  height: 100vh;
}

#main {
  &__container {
    display: grid;
    grid-template-columns: auto 10fr;
    height: 100%;
    &__navbar {
    }
    &__body {
      height: 100%;
      overflow: auto;
      background-color: white;
      &__generic {
        height: 100%;
        padding: 24px 24px;
      }

      &__new_main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 16px;
      }
    }
  }
  &__home_container {
    display: grid;
    grid-template-columns: auto 10fr;
    height: 100%;
    &__navbar {
    }
    &__body {
      height: 100%;
      overflow: auto;
      background-color: white;
      &__generic {
        height: 100%;
      }
      &__container {
        background-image: url("./assests/images/homeGradientBG.svg");
        width: 100%;
        height: 184px;
        position: relative;
        background-size: cover;
        background-position: center;
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: flex-start;

        &__content {
          width: 100%;
          position: absolute;
          top: 65px;
          left: 0;
          background-color: transparent;
          height: calc(100vh - 75px);
          z-index: 30;
          gap: 40px;
          padding-left: 25px;
          padding-right: 24px;
          padding-bottom: 49.7px;

          // display: flex;
          // justify-content: center;
          // align-items: center;
        }
      }
    }
  }
}

.font-design-heading {
  color: $background_color;
  font-family: $font_family;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  text-align: left;
  margin-top: 0;
}
.font-design-sub-heading {
  font-family: $font_family;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $background_color;
  margin-top: 0;
}

button {
  background: none;
  outline: inherit;
  color: inherit;
  border: none;
  font: inherit;
  text-align: left;
  padding: 0;
}

@mixin audit_button() {
  background-color: white;
  padding: 10px 12px;
  border: 1px solid $audit_button_border_color;
  border-radius: 6px;
  font-size: $subheading_font_size;
  font-weight: 400;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: $audit_button_text_color;
  font-family: $font_family;
  white-space: nowrap;
  &:hover:not(.disabled) {
    cursor: pointer;
    box-shadow: 0 0 15px 0 $audit_button_box_shadow_color;
    border: solid 1px $audit_button_hover_border_color;
    color: $audit_button_color;
    > img {
      filter: brightness(0) invert(33%) sepia(55%) saturate(7149%)
        hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
    }
  }
  &.active,
  &.selected {
    box-shadow: 0 0 15px 0 $audit_button_box_shadow_color;
    border: solid 1px $audit_button_active_border_color;
    color: $audit_button_color;
    > img {
      filter: brightness(0) invert(33%) sepia(55%) saturate(7149%)
        hue-rotate(238deg) brightness(98%) contrast(93%) saturate(100%);
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

@mixin overline_heading() {
  font-family: $font_family;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(5, 5, 82, 0.4);
}

@mixin modal_and_drawer_header() {
  font-family: $font_inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
}

@mixin heading_inside_modal_bold() {
  font-family: $font_inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

@mixin heading_inside_modal_semi_bold() {
  font-family: $font_inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@mixin sub_heading {
  font-family: $font_inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin base_font_and_inputs() {
  font-family: $font_inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

@mixin secondary_text() {
  font-family: $font_inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@mixin application_status_tag_common {
  @include secondary_text();
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 8px;
}

@mixin small_bold_font {
  font-family: $font_inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
}

#home_page {
  width: 100%;
  min-height: 148px;
  max-height: auto;
  gap: 20px;
  padding-bottom: 16px;

  &__welcome_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      > span {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(5, 5, 82, 0.8);
      }

      &__emoji_container {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > span {
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(5, 5, 82, 0.8);
    }

    > p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(85, 78, 241, 1);
      cursor: pointer;

      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }

  &__redirectCards_container {
    min-height: 92px;
    max-height: auto;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &__card {
      // width: 425px; //419px
      width: 100%;
      max-width: 425px;
      min-width: 419px;
      height: 92px;
      padding: 16px 16px 16px 16px;
      gap: 20px;
      border: 1px solid rgba(5, 5, 82, 0.10);
      border-radius: 8px 8px 8px 8px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 10px 0px rgba(5, 5, 82, 0.05);
      color: rgba(5, 5, 82, 0.6);

      display: flex;
      align-items: start;
      justify-content: space-between;

      @media (max-width: 1432px) and (min-width: 1360px) {
        width: 419px; // for arc browser
      }
      &:hover {
        cursor: pointer;
      }

    }
  }
}

#icon_section {
  width: 36px;
  height: 36px;
}

#icon_container {
  width: 36px;
  height: 36px;
  padding: 6px;
  border-radius: 6px;
}

#content_container {
  width: 337px;
  height: 56px;

  &__title_container {
    width: 100%;
    height: 20px;
    gap: 0px;

    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    color: #050552CC;

    > p {
      margin: 0;
      margin-right: 4px;
    }
  }

  &__content_container {
    width: 100%;
    min-height: 24px;
    max-height: 36px;
    height: min-content;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

#section_two_videoCard_container {
  width: 100%;
  min-height: 289px;
  max-height: auto;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
}

#videoCard_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > span {
    color: rgba(5, 5, 82, 0.8);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  > button {
    background-color: white;
    padding: 10px 12px;
    border: 1px solid rgba(5, 5, 82, 0.1);
    border-radius: 6px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    color: rgba(5, 5, 82, 0.8);
    white-space: nowrap;
    margin-right: 8px;
    margin-left: 8px;
    width: 26px;
    height: 26px;

    &:hover {
      cursor: pointer !important;
    }

    > img {
      width: 13px;
      height: 13px;
    }
  }
}

#videoCard_container {
  width: 100%;
  min-height: 240.75px;
  height: 100%;
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}


#videoCard_body {
  width: 428px; //423
  height: 240.75px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1432px) and (min-width: 1360px) {
    width: 423px; // for arc browser
  }

  &:hover {
    cursor: pointer;
  }

  #videoCard_body__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    z-index: 1;
  }
}

#playButton_container {
  width: 32px;
  height: 32px;
  z-index: 10;
};

#section_three_faq_container {
  min-height: 327px;
  max-height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: rgba(5, 5, 82, 0.02);
}

#section_three_faq_content_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}

#faq_left_container {
  min-width: 327px;
  max-width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  justify-content: flex-start; /* Align contents to the top */
  align-self: stretch;
  height: auto; /* Ensure the height does not enforce stretching */
  flex-grow: 0;
}

#faq_top_container {
  display: flex;
  width: 100%;
  padding: 12px 0px 12px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > span {
    color: rgba(5, 5, 82, 0.8);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    > a {
      text-decoration: none;
      color: #554ef1;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }
}

#faq_left_bottom_container {
  width: 416px;
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: flex-start;
  align-self: stretch;
  height: auto;
  flex-grow: 0;
}

#faq_right_container {
  display: flex;
  padding: 0px 0px 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  >div {
    width: 100% !important;
  } 
}

// // should remove this before MR is approved @prakasam
// #ds-clickwrap {
//   display: none;
// }

.fade-in {
  opacity: 0; /* Start invisible */
  animation: fadeIn 1s ease-in-out forwards; /* Animate to visible */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); /* Optional: Slide in slightly */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
